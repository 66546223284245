body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../src/assests/fonts/SourceSansPro/SourceSansPro-Regular.ttf");
}
@font-face {
  font-family: "Source Sans Pro Black";
  src: url("../src/assests/fonts/SourceSansPro/SourceSansPro-Black.ttf");
}
@font-face {
  font-family: "Source Sans Pro BlackItalic";
  src: url("../src/assests/fonts/SourceSansPro/SourceSansPro-BlackItalic.ttf");
}
@font-face {
  font-family: "Source Sans Pro Bold";
  src: url("../src/assests/fonts/SourceSansPro/SourceSansPro-Bold.ttf");
}
@font-face {
  font-family: "Source Sans Pro BoldItalic";
  src: url("../src/assests/fonts/SourceSansPro/SourceSansPro-BoldItalic.ttf");
}
@font-face {
  font-family: "Source Sans Pro ExtraLight";
  src: url("../src/assests/fonts/SourceSansPro/SourceSansPro-ExtraLight.ttf");
}
@font-face {
  font-family: "Source Sans Pro Italic";
  src: url("../src/assests/fonts/SourceSansPro/SourceSansPro-Italic.ttf");
}
@font-face {
  font-family: "Source Sans Pro Light";
  src: url("../src/assests/fonts/SourceSansPro/SourceSansPro-Light.ttf");
}
@font-face {
  font-family: "Source Sans Pro LightItalic";
  src: url("../src/assests/fonts/SourceSansPro/SourceSansPro-LightItalic.ttf");
}
@font-face {
  font-family: "Source Sans Pro SemiBold";
  src: url("../src/assests/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf");
}
@font-face {
  font-family: "Source Sans Pro SemiBoldItalic";
  src: url("../src/assests/fonts/SourceSansPro/SourceSansPro-SemiBoldItalic.ttf");
}

.header-titles {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 64px;

  width: 522px;
  height: 24px;
}

.nav-link {
  font-family: "Source Sans Pro" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #ffffff !important;
  padding: 16px 8px !important;
}

.nav-link:hover {
  color: #1F2232 !important;
  background: #FFFFFF !important;
}

.navbar-nav {
  gap: 1.3rem !important;
}

.navbar {
  background: #1F2232 !important;
  padding: 0 !important;
}

.search-input {
  border-radius: 5px;
  border: 2px solid black;
}
/* import bannerImage1 from "../../assests/images/banner-1.jpg"; */
.top-banner-slider-img {
  width: 100%;
  height: 320px;
  max-width: 100%;
  background-image: url(./assests/images/banner-1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 6px;
}
.banner-text-1 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  /* White 40 */

  color: rgba(255, 255, 255, 0.4);

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.banner-heading-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: max(3vw, 20px);
  line-height: 56px;
  /* or 100% */

  display: flex;
  align-items: center;

  /* White */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.banner-text-2 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  display: flex;
  align-items: center;

  /* White */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 3;
  flex-grow: 0;
}

.top-banner-dot {
  width: 4px;
  height: 4px;

  /* White 40 */

  background: rgba(255, 255, 255, 0.4);
  border-radius: 2px;
}
.top-banner-line {
  width: 4px;
  height: 100px;

  /* White */

  background: #ffffff;
  border-radius: 2px;
}
/************************************************
***********top slider*************************
***********************************************/
.top-slider-div-1 {
  max-width: 100%;
  background: url(./assests/images/top-slide-1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  border-radius: 6px;

  width: 305px;
  height: 170px;
  border-radius: 6px;
}
.top-slider-div-2 {
  max-width: 100%;
  background: url(./assests/images/top-slider-2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  border-radius: 6px;

  width: 305px;
  height: 170px;
  border-radius: 6px;
}
.top-slider-div-3 {
  max-width: 100%;
  background: url(./assests/images/top-slide-3.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  border-radius: 6px;

  width: 305px;
  height: 170px;
  border-radius: 6px;
}
.top-slider-div-4 {
  max-width: 100%;
  background: url(./assests/images/top-slide-4.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  border-radius: 6px;

  width: 305px;
  height: 170px;
  border-radius: 6px;
}

.product-container {
  box-sizing: border-box;
  width: auto;
  /* height: 413px; */
  background: #ffffff;
  border-radius: 6px;
}

.product-container:hover {
  border: 1px solid rgba(31, 34, 50, 0.05);
  box-shadow: 4px 4px 10px rgba(31, 34, 50, 0.05);
  cursor: pointer;
}

.product-img {
  width: 100%;
  height: 230px;

  border-radius: 6px;
}

.product-price-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  /* or 133% */
  text-transform: uppercase;
  display: flex;
  /* align-items: center; */
  /* text-align: right; */
  align-self: flex-end;
}
.product-price-number {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  margin-right: 4px;

  /* Raisin Black */

  color: #1f2232;
}

.product-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;

  /* Coral */

  color: #ff7f50;
}

.brand-name {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  /* Raisin Black */

  color: #1f2232;
}

.product-name {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: rgba(31, 34, 50, 0.5);
}

.product-counter-container {
  box-sizing: border-box;

  /* Black 10 */
  /* width: 150px;
  height: 40px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  border: 1px solid rgba(31, 34, 50, 0.1);
  border-radius: 6px;
}

.decrement-product-btn {
  border-right: 1px solid rgba(31, 34, 50, 0.1);
  cursor: pointer;
  padding-right: 1rem;
  padding-left: 0.5rem;
}
.increment-product-btn {
  border-left: 1px solid rgba(31, 34, 50, 0.1);
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 0.5rem;
}
.add-to-cart-btn {
  background: #ff7f50;
  border-radius: 6px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  height: 40px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  /* identical to box height, or 129% */

  text-align: center;

  color: #ffffff;
}

/* .product-btns {
  display: none;
} */
.product-container:hover + .product-btns {
  display: block !important;
}

.product-down-btn {
  align-items: center;

  width: 189px;
  height: 56px;
  cursor: pointer;
  /* Black 5 */

  background: rgba(31, 34, 50, 0.05);
  /* Black 5 */

  border: 1px solid rgba(31, 34, 50, 0.05);
  border-radius: 6px;
}

.back-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;

  /* width: 56px;
  height: 56px; */
  cursor: pointer;
  /* White */

  background: #ffffff;
  /* Black 5 */

  border: 1px solid rgba(31, 34, 50, 0.05);
  border-radius: 6px;
}
.next-btn {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid rgba(31, 34, 50, 0.05);
  border-radius: 6px;
}

.label-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1px 8px;
  gap: 10px;

  position: absolute;
  width: 44px;
  height: 20px;

  /* Fire Engine Red */

  background: #c80428;
  border-radius: 2px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  /* White */

  color: #ffffff;
  margin-top: -220px;
  margin-left: 13px;
}

.slider-heading-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;

  /* Raisin Black */

  color: #1f2232;
}

.reload-icon {
  cursor: pointer;
}
.chat-icon {
  cursor: pointer;
}

.left-flex {
  width: 500px;
  height: 694px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  /* margin: 2rem; */
  /* or 167% */
  max-width: 100%;
  text-align: center;

  color: #ffffff;
  /* Orange Yellow Crayola */

  background-color: #ffd972;
  border-radius: 6px;
  background-image: url(./assests//images/new-items.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.brand-slider-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  /* font-size: 40px; */
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;

  /* Raisin Black */

  color: #1f2232;
}

.bottom-slider-heading-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  /* identical to box height, or 156% */

  color: #1f2232;
}

.error-404-txt {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 56px;

  color: #1f2232;
}
.not-found-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #1f2232;
}

.btn-comback {
  padding: 12px 34px;

  box-sizing: border-box;

  background: #ff7f50;
  border-radius: 6px;

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  border: none;
}

.btn-homePage {
  box-sizing: border-box;
  padding: 12px 34px;
  background: #fbfbfb;
  border: 1px solid rgba(31, 34, 50, 0.1);
  border-radius: 6px;
}

.catalog-children-heading-txt {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  /* identical to box height, or 156% */
  color: #1f2232;
  padding-top: 0.3rem;
}

.error-404-txt {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 56px;

  color: #1f2232;
}
.not-found-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #1f2232;
}

.btn-comback {
  padding: 12px 34px;

  box-sizing: border-box;

  background: #ff7f50;
  border-radius: 6px;

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  border: none;
}

.btn-homePage {
  box-sizing: border-box;
  padding: 12px 34px;
  background: #fbfbfb;
  border: 1px solid rgba(31, 34, 50, 0.1);
  border-radius: 6px;
}

.catalog-children-heading-txt {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  /* identical to box height, or 156% */

  /* Raisin Black */

  color: #1f2232;
  padding-top: 0.3rem;
}
.bottom-slider-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* identical to box height, or 150% */

  /* Black 70 */

  color: rgba(31, 34, 50, 0.7);
}

.footer-container {
  padding: 3rem;
  color: rgba(255, 255, 255, 0.6);
  background: #1f2232;
  padding-top: 3.3rem;
}

.catalog-children-txt {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-top: 0.3rem;

  color: rgba(31, 34, 50, 0.7);
}

.catalog-children-img-1 {
  border-radius: 6px;
  max-width: 100%;
  /* max-height: 955px; */
  /* height: 583px; */
}

.catalog-children-img-2 {
  border-radius: 6px;
  max-width: 100%;
  /* max-height: 176px;
  max-width: 305px; */
}
.catalog-children-img-3 {
  border-radius: 6px;
  max-height: 955px;
  max-width: 100%;
}

.catalog-children-img-round {
  border-radius: 50px;
}

.catalog-children-img-round-txt {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  padding-top: 0.3rem;
  text-align: center !important;

  color: #1f2232;
}

.footer-payment-methods {
  background: #181a26;
  padding: 1rem;
}
.footer-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  /* or 171% */

  /* White */

  color: #ffffff;
}

.footer-active-link {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* identical to box height, or 171% */

  /* White */

  color: #ffffff;
}
.footer-link-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* identical to box height, or 171% */

  /* White 60 */

  color: rgba(255, 255, 255, 0.6);
}

.footer-icon-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* identical to box height, or 171% */

  display: flex;
  align-items: center;

  /* White */

  color: #ffffff;
}
.payment-methods-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* identical to box height, or 133% */

  /* White 40 */

  color: rgba(255, 255, 255, 0.4);
}

.page-history-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: rgba(31, 34, 50, 0.5);

  opacity: 0.56;
}

.active-page-history-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #1f2232;
}

.product-review-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* identical to box height, or 171% */

  padding: 10px;
  text-decoration-line: underline;

  /* Blue Jeans */

  color: #35a7ff;
}

.product-const-txt {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: end;

  /* or 156% */

  text-transform: uppercase;

  /* Raisin Black */

  color: #1f2232;
}

.product-const-count-txt {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  /* identical to box height, or 125% */

  text-transform: uppercase;

  /* Raisin Black */

  color: #1f2232;
}

.product-heading {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;

  color: #1f2232;
}

.product-desc {
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
  color: rgba(31, 34, 50, 0.7);
}

.product-desc1 {
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
  color: rgba(31, 34, 50, 0.7);
  margin-right: 16px;
}

.product-desc2 {
  font-weight: 600;
  font-size: 14px;
  margin-top: 5px;
  color: #1F2232;
}

.product-inc-dec-btn {
  box-sizing: border-box;
  background: #ffffff;

  border: 1px solid rgba(31, 34, 50, 0.2);
  border-radius: 6px;
  padding: 15px;
}

.product-count-2 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  padding: 10px 20px 10px 20px;
}
.product-inc-dec-right-txt {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;

  padding-left: 15px;
  padding-right: 15px;

  margin: 0 !important;
}

.product-counter-container-2 {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  border: 1px solid rgba(31, 34, 50, 0.1);
  border-radius: 6px;
  padding: 10px 15px 10px 15px;
}

.decrement-product-btn-2 {
  border-right: 1px solid rgba(31, 34, 50, 0.2);
  cursor: pointer;
  padding: 10px 20px 10px 20px;
}
.increment-product-btn-2 {
  border-left: 1px solid rgba(31, 34, 50, 0.2);
  cursor: pointer;
  padding: 10px 20px 10px 20px;
}

.add-to-cart-btn-2 {
  background: #ff7f50;
  border-radius: 6px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  color: #ffffff;
  padding: 1rem;
}

.like-btn {
  background: rgba(31, 34, 50, 0.05);
  border-radius: 6px;
  padding: 18px;
}

.delivery-info-txt {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 20px 0px 20px 0px;
  margin: 0 !important;

  color: #1f2232;
}

.delivery-info-link {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 2px;
  padding: 20px 0px 20px 0px;

  color: #35a7ff;
}

.product-heading-2 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;

  color: #1f2232;
}

.product-round-image-container {
  box-sizing: border-box;

  border: 0.5px solid rgba(31, 34, 50, 0.05);
  border-radius: 50px;
}

.product-round-image-txt {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
  padding-left: 15px;

  color: rgba(31, 34, 50, 0.7);
}

.product-review-input {
  box-sizing: border-box;
  flex-grow: 1;
  padding: 12px 16px;
  margin-right: 10px;
  background: #ffffff;
  border: 0.5px solid rgba(31, 34, 50, 0.1);
  border-radius: 6px;
}

.add-review-btn {
  /* padding: 13px 35px; */

  background: #ff7f50;
  border-radius: 6px;
  border: none;

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
}

.review-div {
  box-sizing: border-box;

  padding: 16px;

  background: #ffffff;

  border: 0.5px solid rgba(31, 34, 50, 0.1);
  border-radius: 6px;
}

.review-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;

  color: #2d3e4f;
  margin-top: 4px;
}

.review-date {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  padding-left: 15px;

  display: flex;
  align-items: center;

  color: #2d3e4f;

  opacity: 0.56;
}

.review-desc {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top: 5px;

  color: #2d3e4f;
}

.product-review-pagination-container {
  box-sizing: border-box;
  background: #ffffff;
  /* Black 5 */

  border: 1px solid rgba(31, 34, 50, 0.05);
  border-radius: 8px;
  /* max-width: 242px !important; */

  /* padding: 10px 15px 10px 15px; */
}

.product-review-pagination-txt {
  padding: 10px 20px 10px 20px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  /* identical to box height, or 171% */

  /* Raisin Black */

  color: #1f2232;
  border-right: 1px solid rgba(31, 34, 50, 0.05);

  cursor: pointer;
}

.pop-up-container {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 18px 34px;

  /* White */

  background: #ffffff;
  /* Coral */

  border: 1px solid #ff7f50;
  box-shadow: 4px 4px 10px rgba(31, 34, 50, 0.05);
  border-radius: 6px;
}

.pop-up-txt {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-left: 10px;

  color: #ff7f50;
}
.top-search .dropdown-menu.show {
  display: block;
  filter: drop-shadow(4px 4px 10px rgba(31, 34, 50, 0.05));
  border-radius: 6px;
  border-top: none;
  width: 188px !important;
  transform: translate3d(2px, 40.8px, 0px) !important;
}


.home-padding {
  padding: 0 80px 32px 80px;
}