.App {
  text-align: center;
}

.main-app-container {
  padding: 40px 0;
  background: #fbfbfb;
  min-height: 45vh;
}

.c-p {
  cursor: pointer;
}
.no-padding {
  padding-left: 0;
  padding-right: 0;
}
.page-heading {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #1f2232;
}

.page-padding {
  padding: 0 80px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.form-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: rgba(31, 34, 50, 0.7);
}

.block {
  display: block;
}
.none {
  display: none;
}

@media only screen and (max-width: 850px) and (min-width: 700px) {
  .main-app-container {
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.image-title-container {
  display: flex;
  flex-direction: row;
}
.partner-image {
  margin-right: 16px;
}
.partner-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: #1f2232;
}
.partner-inbracket {
  margin-left: 6px;
  font-size: 16px;
}
.partner-sub-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1f2232;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
