.logobar {
  align-items: center;
  padding: 14px 80px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(42, 46, 67, 0.06);
  max-width: 100%;
}
.main-header-link {
  padding: 10px 0;
}

.dropdown-icon {
  margin-left: 8px;
}

::placeholder {
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

:-ms-input-placeholder {
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  line-height: 24px;
}

::-ms-input-placeholder {
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  line-height: 24px;
}

.search-icon {
  position: relative;
  left: 16px;
  z-index: 1;
  top: 7px;
}
.search-col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.search-container {
  display: flex;
  align-items: center;
}
.main-search {
  display: flex;
  /* width: 576px; */
  border: 1px solid #e7e7e9;
  border-radius: 6px;
}
.search-input-container {
  box-sizing: border-box;
  /* padding: 12px 34px 12px 16px; */
  padding: 0.2rem;
  background: #ffffff;
  border: 1px solid rgba(31, 34, 50, 0.1);
  border-radius: 6px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #1f2232;
}

.search-input {
  border: none !important;
}
.search-input:focus {
  box-shadow: none !important;
}
.search-input-header {
  padding-left: 40px !important;
  width: 100%;
  border-radius: 6px 6px 0px 0px;
  background: #ffffff;
  height: 44px;
  /* border: 1px solid #e7e7e9; */
  border: none;
}
.search-input-header:active {
  border: none;
  /* border: 1px solid #e7e7e9; */
}
.search-input-header:focus {
  outline: none;
  border: none;
  /* border: 1px solid #e7e7e9; */
}
.search-suggestions-container {
  position: absolute;
  margin-top: 44px;
}
.suggestion-item-ul {
  padding-left: 0;
  width: 408px;
  max-height: 230px;
  list-style: none;
  background: #ffffff;
  border-radius: 0 0 6px 6px;
}
.suggestion-item {
  cursor: pointer;
  padding: 10px 12px 10px 16px;
}

.header-drop-down {
  position: relative;
}

.btn-outline-secondary {
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-left: 1px solid rgba(31, 34, 50, 0.1) !important;
  color: #1f2232 !important;
}

.btn-outline-secondary:active {
  border: none !important;
  background-color: #ffffff !important;
  border-left: 1px solid rgba(31, 34, 50, 0.1) !important;
}
.btn-outline-secondary:hover {
  border: none !important;
  background-color: #ffffff !important;
  color: #1f2232 !important;
  border-left: 1px solid rgba(31, 34, 50, 0.1) !important;
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: #1f2232 !important;
  background-color: #ffffff !important;

  /* color: var(--bs-btn-active-color); */
  /* background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color); */
}

.dropdown-toggle {
  border: none;
  padding: 10px 16px;
}
.dropdown-toggle::after {
  border-right: 0 !important;
  border-bottom: 0 !important;
  border-left: 0 !important;
}

.dropdow-item-container {
  width: auto;
}
.dropdown-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  width: 100%;
}

.dropdown-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 12px 10px 16px;
  gap: 10px;

  width: 182px;
  height: 44px;

  background: #ffffff;
}

.v-border {
  border-right: 2px solid #e7e7e9;
  margin: 8px 0;
}

/* @media only screen and (max-width: 1530px) and (min-width: 1395px) {
  .main-search {
    width: 480px;
  }
} */

@media only screen and (max-width: 1530px) and (min-width: 1422px) {
}

.user-mail {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}
