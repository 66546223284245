.pop-up {
  width: 360px !important;
}

@media (max-width: 576px) {
  .pop-up {
    width: auto !important;
  }
}
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8%;
}
.login-div {
  border: 1px solid #d4d2d8;
  padding: 40px;
  border-radius: 4px;
}

.pop-up .modal-content {
  display: flex;
  padding: 30px;
  gap: 10px;
  height: 522px !important;
  background: #ffffff;
  border-radius: 6px;
}

.pop-up-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pop-up-close-icon-desktop {
  position: absolute;
  left: 102.78%;
  right: 26.78%;
  top: -0.22%;
  bottom: 23.78%;
  cursor: pointer;
}

.pop-up-close-icon-mobile {
  cursor: pointer;
}

@media (max-width: 576px) {
  .pop-up-close-icon-desktop {
    display: none;
  }
}

@media (min-width: 576px) {
  .pop-up-close-icon-mobile {
    display: none;
  }
}

.pop-up-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: #1f2232;
}

.form-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: rgba(31, 34, 50, 0.7);
}

.form-check-box-label {
  font-weight: 400;
  font-size: 14px;
  color: #1f2232;
}

.form-switch-label .form-switch .form-check-input {
  width: 46px;
  margin-left: -3.5em;
  background-image: url(../../assests/icons/Knob.svg);
  transition: unset !important;
}

.form-switch-label .form-switch .form-check-input:checked {
  background-image: url(../../assests/icons/Knob.svg);
}

.form-control:focus {
  border-color: unset !important;
  box-shadow: unset !important;
}

.form-check-input:focus {
  border-color: unset !important;
  box-shadow: unset !important;
}

.form-switch-label .form-switch .form-check-input:focus {
  background-image: url(../../assests/icons/Knob.svg);
}

.form-switch-label .form-check-input:active {
  filter: unset;
}

input[type="checkbox"]:focus {
  border-color: unset !important;
  box-shadow: unset !important;
}

.form-switch-label .form-check-input {
  height: 24px;
  background-color: #d4d2d8;
  border: unset;
}

.form-switch-label label {
  margin-left: 10px;
  font-size: 14px;
  color: #1f2232;
}

.register-as {
  font-size: 12px;
  color: rgba(31, 34, 50, 0.7);
}

.form-switch-label .form-check {
  display: flex;
  align-items: center;
}

.form-switch-label .form-switch {
  padding-left: 3.5em;
}

.form-switch-label .form-check-input:checked {
  background-color: #ff7f50;
  border-color: unset;
}

.user-sign-button {
  display: flex;
  justify-content: center;
}

.user-sign-button .btn-primary {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  padding: 12px 34px;
  gap: 10px;
  width: 300px;
  height: 48px;
  color: #ffffff;
  background: #ff7f50;
  border-radius: 6px;
  border: none;
}

.user-sign-button .btn:hover {
  color: #ffffff;
  background-color: #ff7f50;
  border-color: unset;
}

.user-sign-button .btn:focus-visible {
  color: #ffffff;
  background-color: #ff7f50;
  border-color: unset;
  outline: 0;
  box-shadow: unset;
}

.user-sign-button .btn:first-child:active {
  color: #ffffff;
  background-color: #ff7f50;
  border-color: unset;
}

.divider {
  position: relative;
  left: 0;
  top: 12px;
  width: 34px;
  height: 1px;
  background: rgba(31, 34, 50, 0.1);
  border-radius: 2px;
}

.social-networks-line {
  display: flex;
  justify-content: space-between;
}

.social-networks {
  font-weight: 400;
  font-size: 14px;
  color: rgba(31, 34, 50, 0.5);
}

.social-networks-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.google-button {
  padding: 8px 16px !important;
  width: 140px;
  height: 48px;
  background: rgba(31, 34, 50, 0.05) !important;
  border-radius: 6px;
  border-color: unset !important;
}

.linkedin-button {
  padding: 8px 16px !important;
  width: 140px;
  height: 48px;
  background: #1275b1 !important;
  border-radius: 6px;
  border-color: unset !important;
}

.new-user-login {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  color: #1f2232;
  cursor: pointer;
}

.new-user-login-link {
  color: #ff7f50;
}

.registration-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
}

.registration-height .modal-content {
  height: 608px !important;
}

.registration-height-check-box .modal-content {
  height: 689px !important;
}
